import { useContext } from 'solid-js';
import { Button, Form, Heading, Picture, TroonAccess } from '@troon/ui';
import { Icon } from '@troon/icons';
import { AccessElement } from '../../../../components/access-element';
import { ReservationCtx } from '../../../../providers/reservation';
import { gql, mutationAction, useMutation } from '../../../../graphql';
import type { ReservationUser } from '../../../../graphql';

type Props = {
	reservationUser: ReservationUser;
};

export function ShareGuestPass(props: Props) {
	const data = useContext(ReservationCtx)!;

	const handleAddGuestPass = useMutation(addGuestPass);

	return (
		<>
			<div
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class="relative flex aspect-[3/2] w-full items-center justify-center px-8 py-4"
			>
				<Picture
					src={data()?.reservation.facility.metadata?.hero?.url}
					sizes={[[720, 480]]}
					class="absolute inset-0 w-full"
				/>
				<div class="absolute inset-0 bg-black/20" />
				<AccessElement
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="flex aspect-[3/2] w-96 items-stretch justify-stretch shadow-xl"
					size="48"
					direction="ccw"
				>
					<div class="grid size-full grid-cols-2 p-4">
						<div class="col-span-2">
							<Icon name="user-circle" class="size-16" />
						</div>
						<div class="flex flex-col items-start justify-end">
							<p class="text-xl font-semibold uppercase">{data()?.reservation.guestPassDiscount?.displayValue} off</p>
							<p class="text-sm uppercase">Guest pass</p>
							<p class="text-sm">
								{props.reservationUser.user?.firstName} {props.reservationUser.user?.lastName}
							</p>
						</div>
						<div class="flex flex-col items-end justify-end">
							<TroonAccess class="w-32" />
						</div>
					</div>
				</AccessElement>
			</div>

			<div class="flex flex-col gap-4 p-6 md:gap-8 md:p-8">
				<div class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Share this Guest Pass with {props.reservationUser.user?.firstName} {props.reservationUser.user?.lastName}
					</Heading>
					<p>
						By sharing 1 of your {data()?.guestPasses.available.length ?? 0} remaining Guest Passes, you’ll save{' '}
						{props.reservationUser.user?.firstName} {data()?.reservation.guestPassDiscount?.displayValue} on this tee
						time.
					</p>
				</div>

				<Form action={handleAddGuestPass} document={addGuestPassToUserMutation}>
					<input type="hidden" name="reservationUserId" value={props.reservationUser.id} />
					<input type="hidden" name="__reservationId" value={data()?.reservation.id} />
					<Button type="submit">Share Guest Pass</Button>
				</Form>
			</div>
		</>
	);
}

const addGuestPassToUserMutation = gql(`mutation addGuestPassToUser($reservationUserId: String!) {
  addGuestPassToUser(reservationUserId: $reservationUserId) { id }
}`);

const addGuestPass = mutationAction(addGuestPassToUserMutation, {
	revalidates: ['reservationInformation'],
	toast: 'Guest pass shared',
	track: {
		event: 'addGuestPass',
		transform: (data) => ({
			reservationId: data.get('__reservationId') as string,
			reservationUserId: data.get('reservationUserId') as string,
		}),
	},
});
